<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="pa-2"
      :elevation="hover ? 10 : 3"
      rounded="lg"
      height="100%"
      style="transition: all 0.2s"
    >
      <v-chip
        v-if="connector.hasUpdate"
        style="position: absolute; top: -10px; left: -25px"
        small
        color="error"
        class="ml-3"
      ></v-chip>

      <v-list-item>
        <v-list-item-content>
          <div class="text-overline mb-4">
            <v-chip
              v-if="connector.directDownload && connector.stable"
              small
              color="green lighten-2 white--text"
              class="mt-2 mr-2"
            >
              {{ connector.stable.Number }}
            </v-chip>
            <v-chip
              v-else-if="connector.versions && connector.versions.length > 0"
              small
              color="green lighten-2 white--text"
              class="mt-2 mr-2"
            >
              {{ connector.versions[0].Number }}
            </v-chip>
            <v-chip
              v-if="connector.community"
              v-tooltip="`This connector is a community contribution by ` + connector.community"
              small
              color="red lighten-2 white--text"
              class="mt-2"
            >
              community
            </v-chip>
          </div>
          <v-list-item-title class="text-h5 mb-1">
            {{ connector.name }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-avatar tile size="80">
          <v-img v-if="connector.feature_image" contain :src="connector.feature_image" />
        </v-list-item-avatar>
      </v-list-item>
      <v-card-text v-if="connector.description" style="min-height: 150px">
        {{ connector.description }}
      </v-card-text>
      <v-card-actions>
        <v-menu v-if="connector.directDownload" bottom offset-y max-height="400">
          <template #activator="{ on }">
            <div class="split-btn">
              <v-btn
                color="primary"
                dark
                class="main-btn rounded-r-0 pr-2"
                elevation="0"
                @click="downloadOriginalFile(connector.stable)"
              >
                Download
              </v-btn>
              <v-btn
                color="primary"
                dark
                class="actions-btn rounded-l-0 pl-0 pr-2"
                elevation="0"
                v-on="on"
              >
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </div>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in connector.versions"
              :key="index"
              dense
              two-line
              color="red"
              @click="downloadOriginalFile(item)"
            >
              <v-list-item-content>
                <v-list-item-title class="caption">{{ item.Number }}</v-list-item-title>
                <v-list-item-subtitle class="caption">
                  <timeago :datetime="item.Date" class="mr-2"></timeago>

                  <v-icon v-if="item.Os === 0" v-tooltip="`Windows Release`" class="mr-2" small>
                    mdi-microsoft-windows
                  </v-icon>
                  <v-icon v-if="item.Os === 1" v-tooltip="`Mac Release`" class="mr-2" small>
                    mdi-apple
                  </v-icon>
                  <v-span
                    v-if="item.Os === 3"
                    v-tooltip="`Manual Installer (Any OS)`"
                    class="mr-2"
                    small
                  >
                    manual
                  </v-span>
                  <v-span
                    v-if="item.Architecture === 1"
                    v-tooltip="`ARM Architecture`"
                    class="mr-2"
                    small
                  >
                    arm64
                  </v-span>
                  <v-span
                    v-if="item.Architecture === 2"
                    v-tooltip="`Intel Architecture`"
                    class="mr-2"
                    small
                  >
                    x64
                  </v-span>

                  <v-icon
                    v-if="item.Prerelease"
                    v-tooltip="`This is a Pre-Release`"
                    class="mr-2"
                    small
                  >
                    mdi-flask
                  </v-icon>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-btn
          
          class="ml-2 my-3 environment white--text"
          @click="downloadOriginalFile"
        >
          <v-icon left>mdi-download</v-icon>
          download
        </v-btn> -->
        <!-- <v-btn class="ml-2 my-3 info" :href="infoUrl" target="_blank">
          <v-icon left>mdi-information</v-icon>
          info
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import mixpanel from 'mixpanel-browser'

export default {
  name: 'Connector',

  props: {
    connector: Object
  },
  computed: {
    infoUrl() {
      return `https://speckle.systems/tag/${this.connector.slug}/`
    }
  },
  methods: {
    async downloadOriginalFile(version) {
      //default to pre-release if no stable is available
      if (
        !version &&
        !this.connector.stable &&
        this.connector.versions &&
        this.connector.versions.length > 0
      )
        version = this.connector.versions[0]

      let splittedName = version.Url.split('/')

      let a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = version.Url
      a.download = splittedName[splittedName.length - 1]
      a.click()
      document.body.removeChild(a)

      mixpanel.track('Connector Download', {
        name: this.connector.slug,
        version: version.number,
        type: 'download'
      })
    }
  }
}
</script>
<style>
.actions-btn {
  min-width: 0px !important;
}
.split-btn {
  display: inline-block;
}
</style>
