import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VTooltip from 'v-tooltip'
import VueTimeago from 'vue-timeago'

Vue.config.productionTip = false
Vue.use(VueTimeago, { locale: 'en' })
Vue.use(VTooltip, { defaultDelay: 300 })

new Vue({
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
